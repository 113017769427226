import React from 'react';
import { Input } from 'antd';

const UserBio = ({
    user, userProfile, editing, value, onChange, handleEdit, handleSave,
}) => {
    const bioWithEdit = editing ? (
        <div>
            <Input
                style={styles.input}
                placeholder={userProfile.bio}
                value={value}
                onChange={e => {
                    onChange(e);
                }}
            />
            <span style={{ fontStyle: 'italic', color: 'red' }} onClick={handleSave}>
                Save
            </span>
        </div>
    ) : (
        <div>
            <span style={{ fontStyle: 'italic', color: 'red' }} onClick={handleEdit}>
                Edit Bio
            </span>
            <p style={{ textAlign: 'center' }}>{userProfile.bio}</p>
        </div>
    );

    const bio = <p style={{ textAlign: 'center', marginTop: '20px' }}>{userProfile.bio}</p>;

    if (user.uid === userProfile.uid) {
        return bioWithEdit;
    }
    return bio;
};

const styles = {
    button: {
        backgroundColor: '#ff0000',
        borderColor: '#ff0000',
        margin: '20px',
    },
    userName: {
        fontFamily: 'Roboto-Bold',
        color: '#757575',
        fontStyle: 'bold',
    },
    userBio: {
        marginTop: '10px',
    },
    input: {
        boxShadow: 'none',
        border: 'none',
        textAlign: 'center',
    },
};

export default UserBio;
